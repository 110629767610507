<template>
  <div class="card card-custom card-border cursor-pointer" @click="$emit('onClickTaskIndexCard', {task_id: task.id, task_type: taskType, request_id: requestId, no_of_task: noOfTask, collection_number: task.collection_number, item_id: itemId})"
       style="box-shadow: -5px -5px rgba(100, 100, 100, 0.1), 5px 5px rgba(100, 100, 100, 0.1) !important; background-color: rgb(245, 245, 245) !important;">
    <div class="card-header">
      <div class="card-title">
        <span v-if="taskType === 1"
            v-html="getIconByKey('icons.meeting.pop-up-meeting', {
                class: 'w-50px h-50px object-cover d-inline-block mr-3'
              })">
        </span>
        <span v-if="taskType === 2"
            v-html="getIconByKey('icons.sample_instruction.tasks', {
                class: 'w-50px h-50px object-cover d-inline-block mr-3'
              })">
        </span>

        <h2 class="card-label">{{ task.collection_number }}</h2>
      </div>
      <div class="card-toolbar">
        <span href="#"
              class="btn btn-icon btn-danger btn-xs p-0 rounded-full"
              style="border-radius: 50% !important;" v-if="noOfTask > 1">
          {{ noOfTask }}
        </span>

        <span href="#"
              class="btn btn-icon btn-success btn-xs p-0 rounded-full"
              style="border-radius: 50% !important;" v-if="isRequested && Number(taskType) > 1">

          <i class="fa fa-check"></i>
        </span>
      </div>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-between" v-if="Number(taskType) === 1">
        <p class="flex-40"><b>{{ $t('task.meeting_name') }} </b></p>
        <p class="text-right px-2 font-weight-bolder">:</p>
        <p class="pl-3 flex-60">{{ meetingName }}</p>
      </div>
      <div class="d-flex justify-content-between" v-else>
        <p class="flex-40"><b>{{ $t('task.sample_name') }} </b></p>
        <p class="text-right px-2 font-weight-bolder">:</p>
        <p class="pl-3 flex-60">{{ sampleName || '-' }}</p>
      </div>

      <div class="d-flex justify-content-between" v-if="Number(taskType) === 2">
        <p class="flex-40"><b>{{ $t('task.fabric_type') }} </b></p>
        <p class="text-right px-2 font-weight-bolder">:</p>
        <p class="pl-3 flex-60">{{ fabricName || '-' }} {{ ' - '+color || '' }}</p>
      </div>

      <div class="d-flex justify-content-between" v-if="Number(taskType) === 2">
        <p class="flex-40"><b>{{ $t('task.brand_manager') }} </b></p>
        <p class="text-right px-2 font-weight-bolder">:</p>
        <p class="pl-3 flex-60">{{ brandManager || '-' }}</p>
      </div>

      <div class="d-flex justify-content-between" v-if="Number(taskType) === 3">
        <p class="flex-40"><b>{{ $t('task.accessory_type') }} </b></p>
        <p class="text-right px-2 font-weight-bolder">:</p>
        <p class="pl-3 flex-60">{{ task.accessory.translation.name }}</p>
      </div>
      <div class="d-flex justify-content-between">
        <p class="flex-40"><b>{{ $t('task.sample_creator') }}</b></p>
        <p class="text-right px-2 font-weight-bolder">:</p>
        <p class="pl-3 flex-60">{{ sampleCreator }}</p>
      </div>
      <div class="d-flex justify-content-between">
        <p class="flex-40"><b>{{ $t('task.client_name') }}</b></p>
        <p class="text-right px-2 font-weight-bolder">:</p>
        <p class="pl-3 flex-60">{{ clientName }}</p>
      </div>
      <div class="d-flex justify-content-between">
        <p class="flex-40">
          <b>{{ $t('task.order_date') }}</b></p>
        <p class="text-right px-2 font-weight-bolder">:</p>
        <p class="pl-3 flex-60">{{ orderDate | momentDateFormat }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaskIndexCard",
  props: {
    task: {
      required: true,
    },
    meetingId: {
      required: true,
    },
    itemId: {
      required: false,
      default: null
    },
    noOfTask: {
      required: false,
      type: Number,
      default: 1,
    },
    meetingName: {
      required: true,
    },
    sampleName: {
      required: false,
    },
    sampleCreator: {
      required: true,
    },
    clientName: {
      required: true,
    },
    orderDate: {
      required: true,
    },
    requestId: {
      required: true,
    },
    isRequested: {
      required: true,
    },
    fabricName: {
      required: false,
    },
    color: {
      required: false,
    },
    brandManager: {
      required: false,
    },
    taskType: {
      required: true,
      validation: function(l){
        return [1, 2, 3].indexOf(l) > -1;
      }
    }
  },
  methods: {
    addZerosToId(id){
      id = Number(id);
      if(isNaN(id)) {
        return '000000';
      }
      if(id < 9){
        return '0000' + id;
      }else if( id < 99) {
        return '000' + id;
      }else if(id < 999) {
        return '00' + id;
      }else if(id < 9999) {
        return '0' + id;
      }else {
        return id;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.demo.scss';

.flex-40 {
  flex: 40% !important;;
}

.flex-60 {
  flex: 60% !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.card-header {
  padding: 0 10px;
  background-color: #ffffff;
  border-bottom: 0px;
}

.card.card-custom > .card-body {
  padding: 0 10px;
}

.card-custom {
  border-radius: 20px !important;
  border: 3px solid $primary-gray !important;
  box-shadow: 0.5px 1px $primary-gray-shadow !important;
}

.card {

}
</style>
